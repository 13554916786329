import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO() {
    const { site, image } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            siteUrl
          }
        },
        image: file(relativePath: { eq: "main-carousel/0001.jpg" }) {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    `
    );

    const lang = 'pl';

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={site.siteMetadata.title}
            link={
                site.siteMetadata.siteUrl
                    ? [
                        {
                            rel: "canonical",
                            href: site.siteMetadata.siteUrl,
                        },
                    ]
                    : []
            }
            meta={[
                {
                    name: `description`,
                    content: site.siteMetadata.description,
                },
                {
                    name: "keywords",
                    content: site.siteMetadata.keywords.join(","),
                },
                {
                    property: `og:title`,
                    content: site.siteMetadata.title,
                },
                {
                    name: `og:description`,
                    content: site.siteMetadata.description,
                },
                {
                    name: `twitter:title`,
                    content: site.siteMetadata.title,
                },
                {
                    name: `twitter:description`,
                    content: site.siteMetadata.description,
                },
                {
                    property: "og:image",
                    content: image.childImageSharp.resize.src,
                },
                {
                    property: "og:image:width",
                    content: image.childImageSharp.resize.width,
                },
                {
                    property: "og:image:height",
                    content: image.childImageSharp.resize.height,
                },
                {
                    name: "twitter:card",
                    content: "summary_large_image",
                },
            ]}
        />
    );
}

export default SEO