import * as React from "react";
import Navbar from "../components/Navbar";
import {Container, CssBaseline} from "@material-ui/core";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Footer from "../components/Footer";
import MainCarousel from "../components/MainCarousel";
import About from "../components/About";
import Contact from "../components/Contact";
import Offer from "../components/Offer";
import Rental from "../components/Rental";
import SEO from "../components/SEO";

const IndexPage = () => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#ffca94',
        main: '#cc9966',
        dark: '#996b3a',
        contrastText: '#000000'
      },
      secondary: {
        light: '#9affff',
        main: '#66cccc',
        dark: '#2d9b9b',
        contrastText: '#242424'
      },
    },
    typography: {
      subtitle2: {

      },
      body2: {
        fontSize: '0.7rem'
      }
    }
  });

  return (
      <main style={{flexGrow: 1}}>
        <SEO />
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Navbar />
          <MainCarousel />
          <Container>
            <About />
            <Offer />
            <Rental />
            {/*<Gallery />*/}
            <Contact/>
          </Container>
          <Footer />
        </ThemeProvider>
      </main>
  )
}

export default IndexPage
