import * as React from "react";
import Grid from "@material-ui/core/Grid";
import SectionTitle from "./SectionTitle";
import {Box, Button, Grow, List, ListItem, ListItemIcon, ListItemText, Slide, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import FolderIcon from "@material-ui/icons/Folder";
import Carousel from "./Rental/Carousel";
import {scroller} from "react-scroll";
import {useInView} from "react-intersection-observer";

const useStyles = makeStyles((theme) => ({
    bigTitle: {
        ...theme.typography.subtitle1,
        textAlign: 'center',
        fontSize: 24,
        letterSpacing: 5, m: 1,
        color: theme.palette.primary.dark,
        fontWeight: 'bold'
    },
    smallTitle: {
        ...theme.typography.subtitle1,
        textAlign: 'center',
        fontSize: 20,
        letterSpacing: 5, m: 1,
        color: theme.palette.secondary.dark
    },
    offerTitle: {
        ...theme.typography.subtitle1,
        textAlign: 'center',
        fontSize: 20,
        letterSpacing: 3, m: 1,
        color: theme.palette.secondary.dark
    },
    orderTitle: {
        textAlign: 'center',
    },
    orderButton: {
        ...theme.typography.subtitle1,
        fontSize: 20,
        letterSpacing: 3, m: 1,
        color: theme.palette.primary.main
    },
    textA: {
        ...theme.typography.body1,
        textAlign: 'justify',
        textAlignLast: 'justify',
    },
    textB: {
        ...theme.typography.body1,
        textAlign: 'justify',
    },
    textC: {
        ...theme.typography.body2,
        fontSize: 14,
        textAlign: 'justify',
    },
    textAHighlighted: {
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    },
    textBHighlighted: {
        color: theme.palette.secondary.dark,
        fontWeight: 'bold'
    }
}));

const scrollToSection = (section, view) => {
    let offset = -64;
    if (view === 'xs') {
        offset = -64;
    }

    if (view === 'sm') {
        offset = -64;
    }

    scroller.scrollTo(section, {
        offset: offset,
        duration: 1200,
        delay: 0,
        smooth: "easeInOutQuart",
    })
};
const Rental = () => {
    const classes = useStyles();

    const scrollTo = (section) => scrollToSection(section);

    const [showed, setShowed] = React.useState(0);

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
        delay: 500
    });

    const delayedIn = (timeout) => {
        setTimeout(() => {
                setShowed(showed + 1)
            },
            timeout
        )
    }

    return (
        <section id="rental">
            <SectionTitle>Wynajem</SectionTitle>
            <Box style={{overflow: 'hidden'}}>
                <Grid container spacing={4} ref={ref}>
                    <Slide direction="right" in={inView && showed > 0} timeout={400} onEnter={() => delayedIn(200)}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Carousel />
                        </Grid>
                    </Slide>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography component="div">
                            <Grow in={inView && showed > 1} timeout={400} onEnter={() => delayedIn(200)}>
                                <Box className={classes.bigTitle}>PARTY SAUNA</Box>
                            </Grow>
                            <Slide direction="left" in={inView && showed > 0} timeout={400} onEnter={() => delayedIn(200)}>
                                <Box>
                                    <Box className={classes.textA}>
                                        Szukasz pomysłu na ciekawą imprezę ze znajomymi?
                                    </Box>
                                    <Box className={classes.textA}>
                                        Organizujesz <Typography className={classes.textAHighlighted} component="span">urodziny, imieniny, wieczór kawalerski lub panieński</Typography> i szukasz dodatkowych atrakcji? Organizujesz <Typography className={classes.textAHighlighted} component="span">imprezę firmową</Typography> która pozwoli skutecznie zintegrować załogę? Jesteś morsem lub zmarzluchem,  który uwielbia ciepłe klimaty?
                                        A może chciałbyś przetestować saunę przed zamówieniem jej u Nas?
                                    </Box>
                                </Box>
                            </Slide>
                            <br />
                            <Grow in={inView && showed > 2} timeout={400} onEnter={() => delayedIn(200)}>
                                <Box className={classes.smallTitle}>Dobrze trafiłaś/eś!</Box>
                            </Grow>
                            <Slide direction="left" in={inView && showed > 0} timeout={400} onEnter={() => delayedIn(200)}>
                                <Box>
                                    <Box className={classes.textA}>
                                        Oferujemy mobilną <Typography className={classes.textAHighlighted} component="span">Party Saunę</Typography>, która <Typography className={classes.textAHighlighted} component="span">dojedzie na każdą imprezę</Typography>
                                    </Box>
                                    <Box className={classes.textB}>
                                        i <Typography className={classes.textAHighlighted} component="span">w każde miejsce</Typography> jakie tylko przyjdzie Ci do głowy! Opalana drewnem Party Sauna pomieści wygodnie 6 osób. Nocne saunowanie rozjaśni Wam oświetlenie LED RGB, a dla miłośników muzyki radio MP3 z USB, dzięki temu nawet w saunie możecie słuchać ulubionych utworów muzycznych.
                                    </Box>
                                </Box>
                            </Slide>
                            <br />
                            <Grow in={inView && showed > 3} timeout={400} onEnter={() => delayedIn(200)}>
                                <Box className={classes.offerTitle}>Koszty wynajmu</Box>
                            </Grow>
                            <Slide direction="left" in={inView && showed > 0} timeout={400} onEnter={() => delayedIn(200)}>
                                <Box>
                                    <Box>
                                        <List dense={true} style={{paddingTop: '0.7rem'}}>
                                            <ListItem style={{paddingLeft: '8px'}}>
                                                <ListItemIcon style={{minWidth: '40px'}}>
                                                    <FolderIcon color={'primary'} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary="450 zł / pierwszą dobę"
                                                />
                                            </ListItem>
                                            <ListItem style={{paddingLeft: '8px'}}>
                                                <ListItemIcon style={{minWidth: '40px'}}>
                                                    <FolderIcon color={'primary'} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary="600 zł / weekend (2 dni)"
                                                />
                                            </ListItem>
                                            <ListItem style={{paddingLeft: '8px'}}>
                                                <ListItemIcon style={{minWidth: '40px'}}>
                                                    <FolderIcon color={'primary'} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary="100 zł / każdy kolejny dzień"
                                                />
                                            </ListItem>
                                        </List>
                                    </Box>
                                    <Box className={classes.textC}>
                                        Cena zawiera transport do <Typography className={classes.textBHighlighted} component="span">30km</Typography>.
                                        Do ceny należy doliczyć koszty transportu ustalane indywidualnie.
                                        Istnieje możliwość wynajęcia dodatkowych ręczników, olejków, drewna.<br />
                                    </Box>
                                </Box>
                            </Slide>
                            <br />
                            <Grow in={inView} timeout={400} onEnter={() => delayedIn(200)}>
                                <Box>
                                    <Box className={classes.orderTitle} display={{ xs: 'none', sm: 'block'}}>
                                        <Button
                                            className={classes.orderButton}
                                            onClick={() =>{
                                                scrollTo('contact', 'sm')
                                            }}
                                        >
                                            Zadzwoń i zarezerwuj już dziś!
                                        </Button>
                                    </Box>
                                    <Box className={classes.orderTitle} display={{ xs: 'block', sm: 'none'}}>
                                        <Button
                                            className={classes.orderButton}
                                            onClick={() =>{
                                                scrollTo('contact', 'xs')
                                            }}
                                        >
                                            Zadzwoń i zarezerwuj<br/>już dziś!
                                        </Button>
                                    </Box>
                                </Box>
                            </Grow>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </section>
    )
}

export default Rental