import * as React from "react";
import {Box, Container, Link, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FacebookIcon from '@material-ui/icons/Facebook';
import {scroller} from "react-scroll";

const useStyles = makeStyles((theme) => ({
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor:
        theme.palette.primary.light
    },
    item1: {
        [theme.breakpoints.down('sm')]: {
            order: 1,
        },
    },
    item2: {
        [theme.breakpoints.down('sm')]: {
            order: 2,
        },
    },
    item3: {
        [theme.breakpoints.down('sm')]: {
            order: 3,
        },
    },
    item4: {
        [theme.breakpoints.down('sm')]: {
            order: 4,
        },
    }
}));

const scrollToSection = (section, view) => {
    let offset = -64;
    if (view === 'xs') {
        offset = -56;
    }

    scroller.scrollTo(section, {
        offset: offset,
        duration: 1200,
        delay: 0,
        smooth: "easeInOutQuart",
    })
};

const Footer = ({ classes }) => {
    const footerClasses = useStyles();

    const scrollTo = (section, view) => scrollToSection(section, view);

    const menu = [
        {
            name: 'Główna',
            section: 'home'
        },
        {
            name: 'O Nas',
            section: 'about'
        },
        {
            name: 'Oferta',
            section: 'offer'
        },
        {
            name: 'Wynajem',
            section: 'rental'
        },
        {
            name: 'Kontakt',
            section: 'contact'
        },
    ];


    return (
        <footer className={footerClasses.footer}>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={6} sm={6} md={3} style={{paddingBottom: '0.5rem'}} className={footerClasses.item1}>
                        <Box style={{paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
                            <Typography variant={"subtitle2"} style={{paddingBottom: '0.5rem'}}>Menu</Typography>
                            <Typography variant={"body2"}>
                                <Box display={{ xs: 'block', sm: 'none'}}>
                                    {
                                        menu.map((item, i) =>
                                            <Box key={i}>
                                                <Link
                                                    href="#"
                                                    underline="none"
                                                    color="inherit"
                                                    onClick={() => {
                                                        scrollTo(item.section, 'xs');
                                                    }}
                                                >
                                                    {item.name}
                                                </Link>
                                                <br />
                                            </Box>
                                        )
                                    }
                                </Box>
                                <Box display={{xs: 'none', sm: 'block', md: 'block'}}>
                                    {
                                        menu.map((item, i) =>
                                            <Box key={i}>
                                                <Link
                                                    href="#"
                                                    underline="none"
                                                    color="inherit"
                                                    onClick={() => {
                                                        scrollTo(item.section, '');
                                                    }}
                                                >
                                                    {item.name}
                                                </Link>
                                                <br />
                                            </Box>
                                        )
                                    }
                                </Box>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} className={footerClasses.item3}>
                        <Box style={{paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
                            <Typography variant={"subtitle2"} style={{paddingBottom: '0.5rem'}}>Media</Typography>
                            <Link
                                href="https://www.facebook.com/PartySauna"
                                underline="none"
                                color="inherit"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FacebookIcon />&nbsp;
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} className={footerClasses.item2}>
                        <Box style={{paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
                            <Typography variant={"subtitle2"} style={{paddingBottom: '0.5rem'}}>Kontakt</Typography>
                            <Typography variant={"body2"}>
                                Wola 43-225<br />
                                Stawowa 26<br />
                                Tel: 798-811-521, 514-965-259<br />
                                partysauna@gmail.com
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} className={footerClasses.item4}>
                        <Box style={{paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
                            <Typography variant={"subtitle2"} align={'right'} style={{paddingBottom: '0.5rem'}}>
                                &copy; 2021, deseczka.com
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
}

export default Footer