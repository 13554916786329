import * as React from "react";
import Grid from "@material-ui/core/Grid";
import {Box, Grow, List, ListItem, ListItemIcon, ListItemText, Slide, Typography} from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import Sauna24Photos from "./Sauna24/Photos";
import {useInView} from "react-intersection-observer";


const Sauna24 = (offerClasses) => {
    const detailsDelay = 100;

    const [showed, setShowed] = React.useState(0);

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
        delay: 500
    });

    const delayedIn = (timeout) => {
        setTimeout(() => {
                setShowed(showed + 1)
            },
            timeout
        )
    }

    return (
        <div>
            <Box style={{overflow: 'hidden'}} ref={ref}>
                <Grid container spacing={2}>
                    <Slide direction="right" in={inView} timeout={400} onEnter={() => delayedIn(400)}>
                        <Grid item xs={12} sm={12} md={8}>
                            <Sauna24Photos />
                        </Grid>
                    </Slide>
                    <Grid item xs={12} sm={4} md={4}>
                        <Box>
                            <Grow direction="right" in={inView && showed > 0} timeout={400} onEnter={() => delayedIn(200)}>
                                <Typography style={{fontSize: '1.2rem'}}>Sauna 2,4m</Typography>
                            </Grow>
                        </Box>
                        <Box>
                            <List dense={true} style={{paddingTop: '0.7rem'}}>
                                <Grow direction="right" in={inView && showed > 1} timeout={400} onEnter={() => delayedIn(detailsDelay)}>
                                <ListItem style={{paddingLeft: '8px'}}>
                                    <ListItemIcon style={{minWidth: '40px'}}>
                                        <FolderIcon color={'primary'} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Wymiary - 185cm / 180cm / 45cm"
                                        secondary="średnica / długość pomieszczenia saunowego / ganek"
                                        style={{marginTop: '0px', marginBottom: '0px'}}
                                    />
                                </ListItem>
                                </Grow>
                                <Grow direction="right" in={inView && showed > 2} timeout={400} onEnter={() => delayedIn(detailsDelay)}>
                                <ListItem style={{paddingLeft: '8px'}}>
                                    <ListItemIcon style={{minWidth: '40px'}}>
                                        <FolderIcon color={'secondary'} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Warianty pieca"
                                    />
                                </ListItem>
                                </Grow>
                                <List dense={true} style={{paddingTop: '0rem'}}>
                                    <Grow direction="right" in={inView && showed > 3} timeout={400} onEnter={() => delayedIn(detailsDelay)}>
                                    <ListItem style={{paddingLeft: '16px'}}>
                                        <ListItemIcon style={{minWidth: '40px'}}>
                                            <FolderIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Piec opalany drewnem, zewnętrzny"
                                            secondary="komin izolowany na całej długości, barierka osłonowa"
                                            style={{marginTop: '0px', marginBottom: '0px'}}
                                        />
                                    </ListItem>
                                    </Grow>
                                    <Grow direction="right" in={inView && showed > 4} timeout={400} onEnter={() => delayedIn(detailsDelay)}>
                                    <ListItem style={{paddingLeft: '16px'}}>
                                        <ListItemIcon style={{minWidth: '40px'}}>
                                            <FolderIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Piec opalany drewnem, wewnętrzny"
                                            secondary="komin izolowany na całej długości, barierka osłonowa"
                                            style={{marginTop: '0px', marginBottom: '0px'}}
                                        />
                                    </ListItem>
                                    </Grow>
                                    <Grow direction="right" in={inView && showed > 5} timeout={400} onEnter={() => delayedIn(detailsDelay)}>
                                    <ListItem style={{paddingLeft: '16px'}}>
                                        <ListItemIcon style={{minWidth: '40px'}}>
                                            <FolderIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Piec elektryczny, 9kW"
                                            secondary="ze sterownikiem zewnętrznym lub bez"
                                            style={{marginTop: '0px', marginBottom: '0px'}}
                                        />
                                    </ListItem>
                                    </Grow>
                                </List>
                                <Grow direction="right" in={inView && showed > 6} timeout={400} onEnter={() => delayedIn(detailsDelay)}>
                                <ListItem style={{paddingLeft: '8px'}}>
                                    <ListItemIcon style={{minWidth: '40px'}}>
                                        <FolderIcon color={'secondary'} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Opcje dodatkowe"
                                    />
                                </ListItem>
                                </Grow>
                                <List dense={true} style={{paddingTop: '0rem'}}>
                                    <Grow direction="right" in={inView && showed > 7} timeout={400} onEnter={() => delayedIn(detailsDelay)}>
                                    <ListItem style={{paddingLeft: '16px'}}>
                                        <ListItemIcon style={{minWidth: '40px'}}>
                                            <FolderIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Ławki, oparcia oraz podest wykonane z olchy"
                                        />
                                    </ListItem>
                                    </Grow>
                                    <Grow direction="right" in={inView && showed > 8} timeout={400} onEnter={() => delayedIn(detailsDelay)}>
                                    <ListItem style={{paddingLeft: '16px'}}>
                                        <ListItemIcon style={{minWidth: '40px'}}>
                                            <FolderIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Drzwi wejściowe klejone"
                                        />
                                    </ListItem>
                                    </Grow>
                                    <Grow direction="right" in={inView && showed > 9} timeout={400} onEnter={() => delayedIn(detailsDelay)}>
                                    <ListItem style={{paddingLeft: '16px'}}>
                                        <ListItemIcon style={{minWidth: '40px'}}>
                                            <FolderIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Oświetlenie LED RGB"
                                        />
                                    </ListItem>
                                    </Grow>
                                    <Grow direction="right" in={inView && showed > 10} timeout={400} onEnter={() => delayedIn(detailsDelay)}>
                                    <ListItem style={{paddingLeft: '16px'}}>
                                        <ListItemIcon style={{minWidth: '40px'}}>
                                            <FolderIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Oświetlenie zewnętrzne"
                                        />
                                    </ListItem>
                                    </Grow>
                                    <Grow direction="right" in={inView && showed > 11} timeout={400} onEnter={() => delayedIn(detailsDelay)}>
                                    <ListItem style={{paddingLeft: '16px'}}>
                                        <ListItemIcon style={{minWidth: '40px'}}>
                                            <FolderIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Przeszklenie tylnej ściany"
                                            secondary="1/2 lub całość"
                                            style={{marginTop: '0px', marginBottom: '0px'}}
                                        />
                                    </ListItem>
                                    </Grow>
                                    <Grow direction="right" in={inView && showed > 12} timeout={400} onEnter={() => delayedIn(detailsDelay)}>
                                    <ListItem style={{paddingLeft: '16px'}}>
                                        <ListItemIcon style={{minWidth: '40px'}}>
                                            <FolderIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Cebrzyk z chochlą"
                                        />
                                    </ListItem>
                                    </Grow>
                                    <Grow direction="right" in={inView && showed > 13} timeout={400} onEnter={() => delayedIn(detailsDelay)}>
                                    <ListItem style={{paddingLeft: '16px'}}>
                                        <ListItemIcon style={{minWidth: '40px'}}>
                                            <FolderIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Zegar saunowy"
                                        />
                                    </ListItem>
                                    </Grow>
                                    <Grow direction="right" in={inView && showed > 14} timeout={400} onEnter={() => delayedIn(detailsDelay)}>
                                    <ListItem style={{paddingLeft: '16px'}}>
                                        <ListItemIcon style={{minWidth: '40px'}}>
                                            <FolderIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Zestaw olejków"
                                        />
                                    </ListItem>
                                    </Grow>
                                </List>
                            </List>
                        </Box>
                    </Grid>
                    <Grid item md={2} className={offerClasses.descriptionGridSeparator} />
                    <Grid item xs={12} sm={8} md={8}>
                        <Slide direction="right" in={inView && showed > 10} timeout={400} onEnter={() => delayedIn(200)}>
                        <Typography align={'justify'}>
                            Sauna ogrodowa 2,4m to nasz flagowy produkt.
                            Najbardziej lubiany oraz najczęściej wybierany idealnie wkomponuje się w otoczenie Waszego ogrodu.
                        </Typography>
                        </Slide>
                        <br />
                        <Slide direction="left" in={inView && showed > 11} timeout={400} onEnter={() => delayedIn(200)}>
                        <Typography align={'justify'}>
                            W zależności od wybranego wariantu wygdnie pomiesci 4-6 osób siedzących lub 2 leżąch.
                        </Typography>
                        </Slide>
                        <br />
                        <Slide direction="right" in={inView && showed > 12} timeout={400} onEnter={() => delayedIn(200)}>
                        <Typography align={'justify'}>
                            Sauna wykonana ze specjalnie wyselekcjonowanego drewna świerkowego o grubości 42mm.
                            Materiał poddany specjalnej termoobróbce  nadającej mu trwałości oraz odporności na
                            warunki klimatyczne panujące w saunie. Specjalny opracowany sposób łączenia ścianek sprawia,
                            że nie straszny nam upływ czasu, pogoda lub klimat. Sauna pokryta dwoma warstwami powłoki bitumicznej.
                            Kolor gontu można wybrać dowolnie. W standardowym wyposażeniu sauny znajdują się drzwi szklane hartowane,
                            ławki, oparcia oraz podest wykonane z drewna lipowego, oświetlenie wewnętrzne zwykłe oraz dwukrotna impregnacja zewnętrzna na wybrany kolor.
                        </Typography>
                        </Slide>
                        <br />
                        <Slide direction="left" in={inView && showed > 13} timeout={400} onEnter={() => delayedIn(200)}>
                        <Typography align={'justify'}>
                            Zapewniamy zestaw startowy na dobry początek saunowej przygody w postaci termohigrometru, klepsydy oraz cebrzyku z chochla.
                        </Typography>
                        </Slide>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default Sauna24;