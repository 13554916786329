import React from 'react'
import {getImage} from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import {Box, Container, Divider, Paper, Slide, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme) => ({
    greetingPaper: {
        background: fade(theme.palette.primary.dark, 0.62),
        padding: '1.5rem',
        color: '#fff',
        [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            left: '0',
            right: '0',
        },
        [theme.breakpoints.up('sm')]: {
            position: 'absolute'
        },
    },
    greetingArrow: {
        color: theme.palette.primary.contrastText
    },
    greetingDivider: {
        background: 'rgba(255, 255, 255, 0.12)'
    }
}));

const Item = (props) => {
    const classes = useStyles();

    const image = getImage(props.item.image)

    // Use like this:
    const bgImage = convertToBgImage(image)

    return (
        <BackgroundImage
            Tag="div"
            {...bgImage}
            preserveStackingContext
        >
            <div style={{background: 'rgba(0, 0, 0 , 0.25)'}}>
                <Container>
                    <div style={{height: '92vh', position: 'relative'}}>
                        <Box display={{ xs: 'none', sm: 'block' }}>
                            <Slide direction="up" in={true} mountOnEnter>
                                <Paper elevation={5} className={classes.greetingPaper}
                                       style={{top: props.item.top, bottom: props.item.bottom, left: props.item.left, right: props.item.right}}>
                                    <Typography variant="h3">{props.item.name}</Typography>
                                    {props.item.name !== "" ? <Divider variant="middle" className={classes.greetingDivider}/> : ''}
                                    <Typography variant="h5" align={'center'}>{props.item.description}</Typography>
                                    {props.item.name === "" ? <Divider variant="middle" className={classes.greetingDivider}/> : ''}
                                </Paper>
                            </Slide>
                        </Box>
                        <Box display={{ xs: 'block', sm: 'none' }}>
                            <Slide direction="up" in={true} mountOnEnter>
                                <Paper elevation={5} className={classes.greetingPaper}
                                       style={{top: props.item.top, bottom: props.item.bottom}}>
                                    <Typography variant="h4" align={'center'}>{props.item.name}</Typography>
                                    {props.item.name !== "" ? <Divider variant="middle" className={classes.greetingDivider}/> : ''}
                                    <Typography variant="h6" align={'center'}>{props.item.description}</Typography>
                                    {props.item.name === "" ? <Divider variant="middle" className={classes.greetingDivider}/> : ''}
                                </Paper>
                            </Slide>
                        </Box>
                    </div>
                </Container>
            </div>
        </BackgroundImage>
    )
}

export default Item