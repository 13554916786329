import * as React from "react";
import Carousel from "react-material-ui-carousel";
import Item from "./Carousel/Item";
import {graphql, useStaticQuery} from "gatsby";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    buttonWrapper: {
        position: "absolute",
        backgroundColor: "transparent",
        height: "100%",
        top: "0",
        '&:hover': {
            '& $button': {
                backgroundColor: theme.palette.primary.main,
                opacity: "0.8"
            }
        }
    },
    button: {
        margin: "0 10px",
        position: "relative",
        backgroundColor: theme.palette.primary.main,
        top: "calc(50% - 20px) !important",
        color: "#ffffff",
        fontSize: "30px",
        transition: "200ms",
        cursor: "pointer",
        opacity: "0.1",
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: "0.8 !important"
        },
    }
}));

const RentalCarousel = () => {
    const carouselClasses = useStyles();

    const {rental1, rental2, rental3, rental4, rental5} = useStaticQuery(
        graphql`
      query {
        rental1: file(relativePath: { eq: "gallery/201701-swiatowe-morsowanie-polanczyk/0005.JPG" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1280
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        },
        rental2: file(relativePath: { eq: "gallery/201701-wosp-cieszyn/0003.JPG" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1280
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        },
        rental3: file(relativePath: { eq: "gallery/201712-zlot-morsow-znin/0005.JPG" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1280
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        },
        rental4: file(relativePath: { eq: "gallery/201802-morsowanie-czechowice-dziedzice/0002.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1280
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        },
        rental5: file(relativePath: { eq: "gallery/201904-masakrator-rybnik/0004.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1280
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
    );

    const items = [
        {
            image: rental3,
        },
        {
            image: rental4,
        },
        {
            image: rental2,
        },
        {
            image: rental5,
        },
        {
            image: rental1,
        }
    ]

    return (
        <div>
            <Carousel
                indicatorContainerProps={{
                    // style: {
                    //     display: 'none'
                    // }
                }}
                navButtonsWrapperProps={{
                    className: carouselClasses.buttonWrapper
                }}
                navButtonsProps={{

                }}
                NavButton={({onClick, className, style, next, prev}) => {

                }}
            >
                {
                    items.map( (item, i) => <Item key={i} item={item} /> )
                }
            </Carousel>
        </div>
    );
}

export default RentalCarousel