import * as React from "react";
import SectionTitle from "./SectionTitle";
import {Box, Fade, Icon, Paper, Slide, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import {makeStyles} from "@material-ui/core/styles";
import { loadCSS } from 'fg-loadcss';
import ReactPlayer from "react-player";
import {useInView} from "react-intersection-observer";

const useStyles = makeStyles((theme) => ({
    iconBox: {
        position: 'relative',
        width: '100%',
        '&::before': {
            content: "''",
            display: 'block',
            paddingTop: '100%'
        }
    },
    checkoutOffer: {
        color: theme.palette.primary.dark,
        fontWeight: 600,
        letterSpacing: '1px'
    },
    playerPaper: {
        overflow: 'hidden'
    },
    playerWrapper: {
        margin:'auto',
        position: 'relative',
        paddingTop: '56.25%'
    },
    reactPlayer: {
        position: 'absolute',
        top: 0,
        left: 0
    },
    iconSmall: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    iconExtraSmall: {
        margin: '2%',
        width: '22%',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    divTypography: {
        ...theme.typography.body1,
        textAlign: 'justify',
        [theme.breakpoints.down('xs')]: {
            marginRight: -theme.spacing(4)
        },
    }
}));

const About = () => {
    const classes = useStyles();

    const [showed, setShowed] = React.useState(0);

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
        delay: 500
    });

    React.useEffect(() => {
        const node = loadCSS(
            'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );

        return () => {
            node.parentNode.removeChild(node);
        };
    }, []);

    const delayedIn = (timeout) => {
        setTimeout(() => {
                setShowed(showed + 1)
            },
            timeout
        )
    }

    return (
        <section id="about">
            <SectionTitle>O nas</SectionTitle>
            <Box style={{overflow: 'hidden'}}>
                <Grid container spacing={4} ref={ref}>
                    <Slide direction="left" in={inView} timeout={400} onEnter={() => delayedIn(200)}>
                        <Grid container item xs={12} alignItems={'center'} alignContent={'center'} spacing={4}>
                            <Grid item xs={4} sm={3} md={2} className={classes.iconSmall}>
                                <Box style={{margin: '5%'}}>
                                    <Box border={3} borderRadius={'borderRadius'} borderColor="primary.light" className={classes.iconBox}>
                                        <Box style={{top: 0, left: 0, bottom: 0, right: 0}}>
                                            <WbIncandescentIcon color={"primary"} style={{fontSize: 60, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} />
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={9} md={10}>
                                <div className={classes.divTypography}>
                                    <Box style={{float: 'left'}} className={classes.iconExtraSmall}>
                                        <Box border={3} borderRadius={'borderRadius'} borderColor="primary.light" className={classes.iconBox}>
                                            <Box style={{top: 0, left: 0, bottom: 0, right: 0}}>
                                                <WbIncandescentIcon color={"primary"} style={{fontSize: 40, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} />
                                            </Box>
                                        </Box>
                                    </Box>
                                    Nasza przygoda z mobilna sauna rozpoczęła się, gdy oglądając amerykańskie filmy w których surferzy zabierali swoją saunę nad brzeg oceanu. Widok mobilnej sauny w promieniach zachodzącego słońca sprawił, że postanowiliśmy przenieść ten pomysł do Polski.
                                </div>
                            </Grid>
                        </Grid>
                    </Slide>
                    <Slide direction="right" in={(inView && showed > 0)} timeout={400} onEnter={() => delayedIn(200)}>
                        <Grid container item xs={12} alignItems={'center'} alignContent={'center'} spacing={4}>
                            <Grid item xs={12} sm={9} md={10}>
                                <div className={classes.divTypography}>
                                    <Box style={{float: 'right'}} className={classes.iconExtraSmall}>
                                        <Box border={3} borderRadius={'borderRadius'} borderColor="primary.light" className={classes.iconBox}>
                                            <Box style={{top: 0, left: 0, bottom: 0, right: 0}}>
                                                <Icon className="fas fa-hot-tub" color={"primary"} style={{fontSize: 40, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} />
                                            </Box>
                                        </Box>
                                    </Box>
                                    Od pomysłu do realizacji droga krótka - przynajmniej w naszym przypadku. Pierwsza sauna powstawała przez kilka miesięcy, a efekt końcowy zaskoczył nas samych. Wszystko wykonane tak, aby użytkownicy czerpali z sauny jak najwięcej przyjemności. Efektem prac była sauna sucha, która w swoim piecu ma ponad 100 kilogramów kamieni. Dzięki temu potrafi rozgrzać się nawet 120 stopni Celsjusza. Dla komfortu klientów sauna została wyposażona w podświetlenie LED oraz odtwarzacz mp3, dzięki czemu podczas relaksu klienci mogą słuchać swoich ulubionych utworów.
                                </div>
                            </Grid>
                            <Grid item xs={4} sm={3} md={2} className={classes.iconSmall}>
                                <Box style={{margin: '%5'}}>
                                    <Box border={3} borderRadius={'borderRadius'} borderColor="primary.light" className={classes.iconBox}>
                                        <Box style={{top: 0, left: 0, bottom: 0, right: 0}}>
                                            <Icon className="fas fa-hot-tub" color={"primary"} style={{fontSize: 60, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} />
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Slide>
                    <Slide direction="left" in={(inView && showed > 1)} timeout={400} onEnter={() => delayedIn(200)}>
                        <Grid container item xs={12} alignItems={'center'} alignContent={'center'} spacing={4}>
                            <Grid item xs={4} sm={3} md={2} className={classes.iconSmall}>
                                <Box style={{margin: '%5'}}>
                                    <Box border={3} borderRadius={'borderRadius'} borderColor="primary.light" className={classes.iconBox}>
                                        <Box style={{top: 0, left: 0, bottom: 0, right: 0}}>
                                            <Icon className="fas fa-route" color={"primary"} style={{fontSize: 60, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} />
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={9} md={10}>
                                <div className={classes.divTypography}>
                                    <Box style={{float: 'left'}} className={classes.iconExtraSmall}>
                                        <Box border={3} borderRadius={'borderRadius'} borderColor="primary.light" className={classes.iconBox}>
                                            <Box style={{top: 0, left: 0, bottom: 0, right: 0}}>
                                                <Icon className="fas fa-route" color={"primary"} style={{fontSize: 40, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} />
                                            </Box>
                                        </Box>
                                    </Box>
                                    Naszą saunę można postawić niemal wszędzie, gdzie można wjechać samochodem z przyczepką, np. nad jeziorem, rzeką lub swoim podwórku. Korzystanie z niej nie różni się od tego w innej saunie suchej. Nasza konstrukcja gościła do tej pory na kilkunastu zlotach morsów oraz prywatnych imprezach. Jesteśmy w stanie dojechać z naszą sauną na każdą imprezę i w każde miejsce jakie tylko przyjdzie Wam do głowy! Do naszych ciekawych pomysłów dołożyliśmy kolejne – place zabaw, balia party, domki ogrodowe i małą architekturę drewnianą. Wszystko wykonane solidnie z wysokiej jakości drewna.
                                </div>
                            </Grid>
                        </Grid>
                    </Slide>
                    <Grid item xs={12}>
                        <Fade in={(inView && showed > 2)} timeout={400} onEnter={() => delayedIn(200)}>
                            <Typography align={'center'} className={classes.checkoutOffer} variant={"h5"}>
                                Zapraszamy do skorzystania z naszej oferty!
                            </Typography>
                        </Fade>
                    </Grid>
                    <Grid item xs={12}>
                        <Fade in={(inView && showed > 3)} timeout={400}>
                            <Box>
                                <Box style={{maxWidth: '500px', margin: 'auto'}}>
                                    <Paper elevation={3} className={classes.playerPaper}>
                                        <div className={classes.playerWrapper}>
                                            <ReactPlayer
                                                className={classes.reactPlayer}
                                                url='https://deseczka.com/wp-content/uploads/2017/11/sauna.mp4'
                                                controls={true}
                                                width='100%'
                                                height='100%'
                                            />
                                        </div>
                                    </Paper>
                                </Box>
                            </Box>
                        </Fade>
                    </Grid>
                </Grid>
            </Box>
        </section>
    );
}

export default About;