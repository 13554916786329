import * as React from "react";
import Grid from "@material-ui/core/Grid";
import {Box, List, ListItem, ListItemIcon, ListItemText, Typography} from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import BaliaMobilnaPhotos from "./BaliaMobilna/Photos";


const BaliaMobilna = (offerClasses) => {

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8}>
                    <BaliaMobilnaPhotos />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Box>
                        <Typography style={{fontSize: '1.2rem'}}>Balia mobilna</Typography>
                    </Box>
                    <Box>
                        <List dense={true} style={{paddingTop: '0.7rem'}}>
                            <ListItem style={{paddingLeft: '8px'}}>
                                <ListItemIcon style={{minWidth: '40px'}}>
                                    <FolderIcon color={'primary'} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Wymiary - 120cm / 200cm"
                                    secondary="wysokość / średnica"
                                    style={{marginTop: '0px', marginBottom: '0px'}}
                                />
                            </ListItem>
                            <ListItem style={{paddingLeft: '8px'}}>
                                <ListItemIcon style={{minWidth: '40px'}}>
                                    <FolderIcon color={'primary'} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Przyczepa dwuosiowa lekka"
                                    secondary="DMC 750 kg, stopki podporowe, schodek wejściowy"
                                    style={{marginTop: '0px', marginBottom: '0px'}}
                                />
                            </ListItem>
                            <ListItem style={{paddingLeft: '8px'}}>
                                <ListItemIcon style={{minWidth: '40px'}}>
                                    <FolderIcon color={'primary'} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="System masażu powietrznego - 20 dysz"
                                />
                            </ListItem>
                            <ListItem style={{paddingLeft: '8px'}}>
                                <ListItemIcon style={{minWidth: '40px'}}>
                                    <FolderIcon color={'primary'} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="System masażu wodnego - 6 dysz"
                                />
                            </ListItem>
                            <ListItem style={{paddingLeft: '8px'}}>
                                <ListItemIcon style={{minWidth: '40px'}}>
                                    <FolderIcon color={'primary'} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Oświetlenie LED RGB"
                                />
                            </ListItem>
                            <ListItem style={{paddingLeft: '8px'}}>
                                <ListItemIcon style={{minWidth: '40px'}}>
                                    <FolderIcon color={'secondary'} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Schodki wejściowe"
                                />
                            </ListItem>
                            <ListItem style={{paddingLeft: '8px'}}>
                                <ListItemIcon style={{minWidth: '40px'}}>
                                    <FolderIcon color={'secondary'} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Piec opalany drewnem"
                                />
                            </ListItem>
                            <ListItem style={{paddingLeft: '8px'}}>
                                <ListItemIcon style={{minWidth: '40px'}}>
                                    <FolderIcon color={'secondary'} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Opcje dodatkowe"
                                />
                            </ListItem>
                            <List dense={true} style={{paddingTop: '0rem'}}>
                                <ListItem style={{paddingLeft: '16px'}}>
                                    <ListItemIcon style={{minWidth: '40px'}}>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Grzałka elektryczna 12kW"
                                    />
                                </ListItem>
                                <ListItem style={{paddingLeft: '16px'}}>
                                    <ListItemIcon style={{minWidth: '40px'}}>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Uchwyty na napoje"
                                    />
                                </ListItem>
                                <ListItem style={{paddingLeft: '16px'}}>
                                    <ListItemIcon style={{minWidth: '40px'}}>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Pompa filtrująca"
                                    />
                                </ListItem>
                                <ListItem style={{paddingLeft: '16px'}}>
                                    <ListItemIcon style={{minWidth: '40px'}}>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Kolor wkładu z laminatu"
                                    />
                                </ListItem>
                                <ListItem style={{paddingLeft: '16px'}}>
                                    <ListItemIcon style={{minWidth: '40px'}}>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Kolor zewnętrzny"
                                    />
                                </ListItem>
                            </List>
                        </List>
                    </Box>
                </Grid>
                <Grid item md={2} className={offerClasses.descriptionGridSeparator} />
                <Grid item xs={12} sm={8} md={8}>
                    <Typography align={'justify'}>
                        Balia ogrodowa wykonana z specjalnie wyselekcjowanego drewna świerkowego.
                        Wkład z żywicy poliestrowej w wybranym kolorze zapewnia nie tylko cieszące oko walory estetyczne,
                        ale zapewnia wygodę użytkowania oraz łatwość utrzymania czystości.
                        Wyposażona w system masażu powietrznego umili chwile spędzone na relaksie,
                        masaż wodny natomiast pozwoli wypocząć oraz rozluźnić spięte mięśnie po ciężkim dniu.
                        Konstrukcja balii została wyłożona warstwą izolacyjną dzięki której woda nagrzewa się szybciej oraz pozostaje dłużej ciepła.
                        Całość, niczym wisienka na trocie dopełnia izolowana pokrywa z ekoskóry sparwiając,
                        że przechodząc obok balii będziemy chcieli zanużyć się w jej gorących objęciach.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default BaliaMobilna;