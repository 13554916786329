import React from 'react'
import {getImage} from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import {Container} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme) => ({
    greetingPaper: {
        background: fade(theme.palette.primary.dark, 0.62),
        padding: '1.5rem',
        color: '#fff',
        [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            left: '0',
            right: '0',
        },
        [theme.breakpoints.up('sm')]: {
            position: 'absolute'
        },
    },
    greetingArrow: {
        color: theme.palette.primary.contrastText
    },
    imageContainer: {
        [theme.breakpoints.only('xs')]: {
            height: '25vh',
            position: 'relative'
        },
        [theme.breakpoints.only('sm')]: {
            height: '40vh',
            position: 'relative'
        },
        [theme.breakpoints.up('md')]: {
            height: '70vh',
            position: 'relative'
        },
    }
}));

const Item = (props) => {
    const classes = useStyles();

    const image = getImage(props.item.image)

    // Use like this:
    const bgImage = convertToBgImage(image)

    return (
        <BackgroundImage
            Tag="div"
            {...bgImage}
            preserveStackingContext
        >
            <div style={{background: 'rgba(0, 0, 0 , 0.25)'}}>
                <Container>
                    <div className={classes.imageContainer}>
                    </div>
                </Container>
            </div>
        </BackgroundImage>
    )
}

export default Item