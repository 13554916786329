import * as React from "react";
import Grid from "@material-ui/core/Grid";
import {Box, List, ListItem, ListItemIcon, ListItemText, Typography} from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import SaunaMobiAquaPhotos from "./SaunaMobiAqua/Photos";


const SaunaMobiAqua = (offerClasses) => {

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8}>
                    <SaunaMobiAquaPhotos />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Box>
                        <Typography style={{fontSize: '1.2rem'}}>Sauna mobilna aqua</Typography>
                    </Box>
                    <Box>
                        <List dense={true} style={{paddingTop: '0.7rem'}}>
                            <ListItem style={{paddingLeft: '8px'}}>
                                <ListItemIcon style={{minWidth: '40px'}}>
                                    <FolderIcon color={'primary'} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Wymiary - 185cm / 180cm / 45cm"
                                    secondary="średnica / długość pomieszczenia saunowego / ganek"
                                    style={{marginTop: '0px', marginBottom: '0px'}}
                                />
                            </ListItem>
                            <ListItem style={{paddingLeft: '8px'}}>
                                <ListItemIcon style={{minWidth: '40px'}}>
                                    <FolderIcon color={'primary'} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Masa całkowita - 700kg"
                                    style={{marginTop: '0px', marginBottom: '0px'}}
                                />
                            </ListItem>
                            <ListItem style={{paddingLeft: '8px'}}>
                                <ListItemIcon style={{minWidth: '40px'}}>
                                    <FolderIcon color={'primary'} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Przyczepa podłodziowa"
                                    secondary="stopki podporowe, schodek wejściowy"
                                    style={{marginTop: '0px', marginBottom: '0px'}}
                                />
                            </ListItem>
                            <ListItem style={{paddingLeft: '8px'}}>
                                <ListItemIcon style={{minWidth: '40px'}}>
                                    <FolderIcon color={'secondary'} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Piec opalany drewnem, zewnętrzny"
                                    secondary="komin izolowany na całej długości, barierka osłonowa"
                                    style={{marginTop: '0px', marginBottom: '0px'}}
                                />
                            </ListItem>
                            <ListItem style={{paddingLeft: '8px'}}>
                                <ListItemIcon style={{minWidth: '40px'}}>
                                    <FolderIcon color={'secondary'} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Opcje dodatkowe"
                                />
                            </ListItem>
                            <List dense={true} style={{paddingTop: '0rem'}}>
                                <ListItem style={{paddingLeft: '16px'}}>
                                    <ListItemIcon style={{minWidth: '40px'}}>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Ławki, oparcia oraz podest wykonane z olchy"
                                    />
                                </ListItem>
                                <ListItem style={{paddingLeft: '16px'}}>
                                    <ListItemIcon style={{minWidth: '40px'}}>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Drzwi wejściowe klejone"
                                    />
                                </ListItem>
                                <ListItem style={{paddingLeft: '16px'}}>
                                    <ListItemIcon style={{minWidth: '40px'}}>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Oświetlenie LED RGB"
                                    />
                                </ListItem>
                                <ListItem style={{paddingLeft: '16px'}}>
                                    <ListItemIcon style={{minWidth: '40px'}}>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Oświetlenie zewnętrzne"
                                    />
                                </ListItem>
                                <ListItem style={{paddingLeft: '16px'}}>
                                    <ListItemIcon style={{minWidth: '40px'}}>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Przeszklenie tylnej ściany"
                                        secondary="1/2 lub całość"
                                        style={{marginTop: '0px', marginBottom: '0px'}}
                                    />
                                </ListItem>
                                <ListItem style={{paddingLeft: '16px'}}>
                                    <ListItemIcon style={{minWidth: '40px'}}>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Cebrzyk z chochlą"
                                    />
                                </ListItem>
                                <ListItem style={{paddingLeft: '16px'}}>
                                    <ListItemIcon style={{minWidth: '40px'}}>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Zegar saunowy"
                                    />
                                </ListItem>
                                <ListItem style={{paddingLeft: '16px'}}>
                                    <ListItemIcon style={{minWidth: '40px'}}>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Zestaw olejków"
                                    />
                                </ListItem>
                            </List>
                        </List>
                    </Box>
                </Grid>
                <Grid item md={2} className={offerClasses.descriptionGridSeparator} />
                <Grid item xs={12} sm={8} md={8}>
                    <Typography align={'justify'}>
                        Zaprojektowana w sposób aby była dostęna dla każdego, czy to z dużym czy małym samochodem.
                    </Typography>
                    <br />
                    <Typography align={'justify'}>
                        Jej waga wraz z przyczepą to 700 kg.
                    </Typography>
                    <br/>
                    <Typography align={'justify'}>
                        Sauna wykonana ze specjalnie wyselekcjonowanego drewna świerkowego o grubości 42mm.
                        Materiał poddany specjalnej termoobróbce  nadającej mu trwałości oraz odporności na
                        warunki klimatyczne panujące w saunie. Specjalny opracowany sposób łączenia ścianek sprawia,
                        że nie straszny nam upływ czasu, pogoda lub klimat. Sauna pokryta dwoma warstwami powłoki bitumicznej.
                        Kolor gontu można wybrać dowolnie. W standardowym wyposażeniu sauny znajdują się drzwi szklane hartowane,
                        ławki, oparcia oraz podest wykonane z drewna lipowego, oświetlenie wewnętrzne zwykłe oraz dwukrotna impregnacja zewnętrzna na wybrany kolor.
                    </Typography>
                    <br />
                    <Typography align={'justify'}>
                        Zapewniamy zestaw startowy na dobry początek saunowej przygody w postaci termohigrometru, klepsydy oraz cebrzyku z chochla.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default SaunaMobiAqua;