import * as React from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons//MenuOpen';
import {Backdrop, Box, Drawer, Link, MenuItem, styled} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {scroller} from "react-scroll";
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        cursor: "pointer",
        transition: '0.3s',
        '&:hover': {
            color: '#5c5c5c',
            backgroundColor: fade(theme.palette.primary.light, 0.4),
        },
    },
    menuNavBar: {
        flexGrow: 1,
        fontSize: '14px',
        fontWeight: 'bold'
    },
    appBar: {
        backgroundImage: 'url("../static/tileable-wood-colored-h.png")',
        backgroundSize: '250px',
        [theme.breakpoints.down('md')]: {
            alignItems: 'left',
        },
        [theme.breakpoints.up('md')]: {
            alignItems: 'center',
        }
    },
    button: {
        margin: "0 10px",
        position: "relative",
        backgroundColor: theme.palette.primary.main,
        top: "calc(50% - 20px) !important",
        color: theme.palette.primary.contrastText,
        fontSize: "30px",
        transition: "200ms",
        cursor: "pointer",
        '&:hover': {
            opacity: "0.8 !important"
        },
    },
    mobileIconButton: {
        [theme.breakpoints.down('sm')]: {
            padding: '16px',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '20px',
        }
    },
    mobileOpenIconButton: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: '4px',
            padding: '16px',
        },
        [theme.breakpoints.only('sm')]: {
            marginLeft: '12px',
            padding: '20px'
        }
    },
    mobileIconAbsoluteDiv: {
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            top: '16px'
        },
        [theme.breakpoints.only('sm')]: {
            position: 'absolute',
            top: '20px'
        }
    },
    appBarDrawer: {
        backgroundImage: 'url("../static/tileable-wood-colored-h.png")',
        backgroundSize: '250px',
        backgroundColor: theme.palette.primary.main,
    }
}));

const scrollToSection = (section, view) => {
    let offset = -64;
    if (view === 'xs') {
        offset = -56;
    }

    if (view === 'sm') {
        offset = -64;
    }

    scroller.scrollTo(section, {
        offset: offset,
        duration: 1200,
        delay: 0,
        smooth: "easeInOutQuart",
    })
};

const Navbar = () => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleCollapse = () => {
        setOpen(!open);
    };

    const scrollTo = (section, view) => scrollToSection(section, view);

    const menu = [
        {
            name: 'GŁÓWNA',
            section: 'home'
        },
        {
            name: 'O NAS',
            section: 'about'
        },
        {
            name: 'OFERTA',
            section: 'offer'
        },
        {
            name: 'WYNAJEM',
            section: 'rental'
        },
        {
            name: 'KONTAKT',
            section: 'contact'
        },
    ];

    return (
        <AppBar position="sticky" className={classes.appBar}>
            <Toolbar>
                <Box display={{ sm: 'block', md: 'none' }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-haspopup="true"
                        onClick={handleCollapse}
                        className={classes.mobileIconButton}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Drawer
                        anchor={'top'}
                        open={open}
                        onClose={handleCollapse}
                        onOpen={handleCollapse}
                        BackdropComponent={
                            styled(Backdrop, { name: 'MuiModal', slot: 'Backdrop', overridesResolver: (props, styles) => { return styles.backdrop; }, })({backgroundColor: 'rgba(0, 0, 0, 0.1)'})
                        }
                    >
                        <Box className={classes.appBarDrawer} display={{ sm: 'block', md: 'none' }}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-haspopup="true"
                                onClick={handleCollapse}
                                className={classes.mobileOpenIconButton}
                            >
                                <MenuOpenIcon />
                            </IconButton>
                            <Box display={{ xs: 'none', sm: 'block'}}>
                                {
                                    menu.map((item, i) =>
                                        <MenuItem key={i} onClick={() => {
                                            handleCollapse();
                                            scrollTo(item.section, 'sm');
                                        }}>{item.name}</MenuItem>
                                    )
                                }
                            </Box>
                            <Box display={{ xs: 'block', sm: 'none'}}>
                                {
                                    menu.map((item, i) =>
                                        <MenuItem key={i} onClick={() => {
                                            handleCollapse();
                                            scrollTo(item.section, 'xs');
                                        }}>{item.name}</MenuItem>
                                    )
                                }
                            </Box>
                        </Box>
                    </Drawer>
                </Box>
                <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
                    <Typography variant="h6" className={classes.menuNavBar}>
                        {
                            menu.map((item, i) =>
                                <Link key={i} onClick={() => scrollTo(item.section)} color="inherit" className={classes.menuButton} underline={'none'}>
                                    {item.name}
                                </Link>
                            )
                        }
                    </Typography>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Navbar
