import * as React from "react";
import SectionTitle from "./SectionTitle";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {AnonImage} from "./Contact/AnonImage";
import Grid from "@material-ui/core/Grid";
import MailIcon from '@material-ui/icons/Mail';
import PersonalRight from "./Contact/PersonalRight";
import WebIcon from '@material-ui/icons/Web';
import FacebookIcon from '@material-ui/icons/Facebook';
import {OneImage} from "./Contact/OneImage";
import {TwoImage} from "./Contact/TwoImage";

const useStyles = makeStyles((theme) => ({
    circlePaper: {
        borderRadius: '50%',
        width: '120px',
        height: '120px',
        overflow: 'hidden',
    },
    contactName: {
        color: theme.palette.primary.dark,
        fontWeight: 600,
        letterSpacing: '1px'
    },
    contactNumber: {
        paddingTop: '0.5rem',
        paddingBottom: '1rem',
        paddingLeft: '0.5rem',
    }
}));

const Contact = () => {
    const classes = useStyles();

    return (
        <section id="contact">
            <SectionTitle>Kontakt</SectionTitle>
            <Grid container spacing={4} style={{marginBottom: '1.5rem'}}>
                <Grid container item xs={12} sm={12} md={12} lg={4}>
                    <Box style={{marginLeft: '5%', marginTop: '5%', paddingTop: '5%'}}>
                        <Typography className={classes.contactNumber}>
                            <WebIcon color={"secondary"} style={{verticalAlign: 'middle'}} /> deseczka.com
                        </Typography>
                        <Typography className={classes.contactNumber}>
                            <FacebookIcon color={"secondary"} style={{verticalAlign: 'middle'}} /> facebook.com/PartySauna
                        </Typography>
                        <Typography className={classes.contactNumber}>
                            <MailIcon color={"secondary"} style={{verticalAlign: 'middle'}} /> partysauna@gmail.com
                        </Typography>
                    </Box>
                </Grid>
                <PersonalRight name={'Grzegorz'} phoneNumber={'798-811-521'}>
                    <OneImage />
                </PersonalRight>
                <PersonalRight name={'Grzegorz'} phoneNumber={'514-965-259'}>
                    <TwoImage />
                </PersonalRight>
            </Grid>
        </section>
    )
}

export default Contact