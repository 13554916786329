import * as React from "react";
import Typography from '@material-ui/core/Typography';
import {Divider} from "@material-ui/core";

const SectionTitle = ({children}) => (
        <Typography style={{paddingBottom: '24px', paddingTop: '64px', letterSpacing: '1px', textTransform: 'uppercase'}} variant={"h3"} align={"center"} color={'primary'}>
            {children}
            <Divider style={{marginTop: '8px', backgroundColor: '#2d9b9b'}}/>
        </Typography>
    );


export default SectionTitle;