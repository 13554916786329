import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

export function OneImage() {
    return <StaticImage
        src="../../images/contact/grzegorz-1.jpg"
        alt="anon"
        placeholder="blurred"
        layout="fullWidth"
    />
}