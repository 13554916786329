import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {StaticImage} from "gatsby-plugin-image";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{width: '100%'}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
    },
    tabs: {
        width: '25%',
        borderRight: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.only('xs')]: {
            height: '216px',
        },
        [theme.breakpoints.only('sm')]: {
            height: '392px',
        },
        [theme.breakpoints.only('md')]: {
            height: '352px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '443px',
        },
    },
}));

export default function Photos() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}
             style={{
                 overflow: 'hidden',
                 maxWidth: '816px',
                 maxHeight: '443px',
                 width: 'auto',
                 height: 'auto'
             }}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab
                     {...a11yProps(0)}
                     icon={
                         <StaticImage
                             src="../../../images/sauna-34/0002.jpg"
                             alt="deseczka.com"
                             placeholder="blurred"
                             layout="constrained"
                             style={{
                                 maxWidth: '179px',
                                 maxHeight: '111px',
                                 width: 'auto',
                                 height: 'auto'
                             }}
                         />
                     }
                />
                <Tab
                    {...a11yProps(1)}
                    icon={
                        <StaticImage
                            src="../../../images/sauna-34/0004.jpg"
                            alt="deseczka.com"
                            placeholder="blurred"
                            layout="constrained"
                            style={{
                                maxWidth: '179px',
                                maxHeight: '111px',
                                width: 'auto',
                                height: 'auto'
                            }}
                        />
                    }
                />
                <Tab
                    {...a11yProps(2)}
                    icon={
                        <StaticImage
                            src="../../../images/sauna-34/0003.jpg"
                            alt="deseczka.com"
                            placeholder="blurred"
                            layout="constrained"
                            style={{
                                maxWidth: '179px',
                                maxHeight: '111px',
                                width: 'auto',
                                height: 'auto'
                            }}
                        />
                    }
                />
                <Tab
                    {...a11yProps(3)}
                    icon={
                        <StaticImage
                            src="../../../images/sauna-34/0005.jpg"
                            alt="deseczka.com"
                            placeholder="blurred"
                            layout="constrained"
                            style={{
                                maxWidth: '179px',
                                maxHeight: '111px',
                                width: 'auto',
                                height: 'auto'
                            }}
                        />
                    }
                />
                <Tab
                    {...a11yProps(4)}
                    icon={
                        <StaticImage
                            src="../../../images/sauna-34/0006.jpg"
                            alt="deseczka.com"
                            placeholder="blurred"
                            layout="constrained"
                            style={{
                                maxWidth: '179px',
                                maxHeight: '111px',
                                width: 'auto',
                                height: 'auto'
                            }}
                        />
                    }
                />
            </Tabs>
            <TabPanel value={value} index={0} style={{width: '75%', padding: '0.5rem'}}>
                <StaticImage
                    src="../../../images/sauna-34/0002.jpg"
                    alt="deseczka.com"
                    placeholder="blurred"
                    layout="fullWidth"
                    style={{
                        // maxWidth: '596px',
                        maxHeight: '427px',
                        width: 'auto',
                        height: 'auto'
                    }}
                />
            </TabPanel>
            <TabPanel value={value} index={1} style={{width: '75%', padding: '0.5rem'}}>
                <StaticImage
                    src="../../../images/sauna-34/0004.jpg"
                    alt="deseczka.com"
                    placeholder="blurred"
                    layout="fullWidth"
                    style={{
                        // maxWidth: '596px',
                        maxHeight: '427px',
                        width: 'auto',
                        height: 'auto'
                    }}
                />
            </TabPanel>
            <TabPanel value={value} index={2} style={{width: '75%', padding: '0.5rem'}}>
                <StaticImage
                    src="../../../images/sauna-34/0003.jpg"
                    alt="deseczka.com"
                    placeholder="blurred"
                    layout="fullWidth"
                    style={{
                        // maxWidth: '596px',
                        maxHeight: '427px',
                        width: 'auto',
                        height: 'auto'
                    }}
                />
            </TabPanel>
            <TabPanel value={value} index={3} style={{width: '75%', padding: '0.5rem'}}>
                <StaticImage
                    src="../../../images/sauna-34/0005.jpg"
                    alt="deseczka.com"
                    placeholder="blurred"
                    layout="fullWidth"
                    style={{
                        maxHeight: '427px',
                        width: 'auto',
                        height: 'auto'
                    }}
                />
            </TabPanel>
            <TabPanel value={value} index={4} style={{width: '75%', padding: '0.5rem'}}>
                <StaticImage
                    src="../../../images/sauna-34/0006.jpg"
                    alt="deseczka.com"
                    placeholder="blurred"
                    layout="fullWidth"
                    style={{
                        // maxWidth: '596px',
                        maxHeight: '427px',
                        width: 'auto',
                        height: 'auto'
                    }}
                />
            </TabPanel>
        </div>
    );
}
