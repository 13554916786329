import * as React from "react";
import Grid from "@material-ui/core/Grid";
import BaliaZimnaPhotos from "./BaliaZimna/Photos";
import {Box, List, ListItem, ListItemIcon, ListItemText, Typography} from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";


const BaliaZimna = (offerClasses) => {

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8}>
                    <BaliaZimnaPhotos />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Box>
                        <Typography style={{fontSize: '1.2rem'}}>Balia zimna</Typography>
                    </Box>
                    <Box>
                        <List dense={true} style={{paddingTop: '0.7rem'}}>
                            <ListItem style={{paddingLeft: '8px'}}>
                                <ListItemIcon style={{minWidth: '40px'}}>
                                    <FolderIcon color={'primary'} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Wymiary - 120cm / 100cm / 100cm"
                                    secondary="wysokość / szerokość / głębokość"
                                    style={{marginTop: '0px', marginBottom: '0px'}}
                                />
                            </ListItem>
                            <ListItem style={{paddingLeft: '8px'}}>
                                <ListItemIcon style={{minWidth: '40px'}}>
                                    <FolderIcon color={'primary'} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Pojemność - 1000l"
                                />
                            </ListItem>
                            <ListItem style={{paddingLeft: '8px'}}>
                                <ListItemIcon style={{minWidth: '40px'}}>
                                    <FolderIcon color={'secondary'} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Obudowa - świerkowa"
                                />
                            </ListItem>
                            <ListItem style={{paddingLeft: '8px'}}>
                                <ListItemIcon style={{minWidth: '40px'}}>
                                    <FolderIcon color={'secondary'} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Lipowa ławeczka z podestem"
                                />
                            </ListItem>
                        </List>
                    </Box>
                </Grid>
                <Grid item md={2} className={offerClasses.descriptionGridSeparator} />
                <Grid item xs={12} sm={8} md={8}>
                    <Typography align={'justify'}>
                        Zapewni ochłodę po saunie oraz w gorące dni.
                    </Typography>
                    <br />
                    <Typography align={'justify'}>
                        Balia ogrodowa wykonana z specjalnie wyselekcjowanego drewna świerkowego. Wkład z żywicy poliestrowej w wybranym kolorze zapewnia nie tylko cieszące oko walory estetyczne, ale zapewnia wygodę użytkowania oraz łatwość utrzymania czystości.  Wyposażona w system masażu powietrznego umili chwile spędzone na relaksie, masaż wodny natomiast pozwoli wypocząć oraz rozluźnić spięte mięśnie po ciężkim dniu.  Konstrukcja balii została wyłożona warstwą izolacyjną dzięki której woda nagrzewa się szybciej oraz pozostaje dłużej ciepła. Całość, niczym wisienka na trocie dopełnia izolowana pokrywa z ekoskóry sparwiając, że przechodząc obok balii będziemy chcieli zanużyć się w jej gorących objęciach.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default BaliaZimna;