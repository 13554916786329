import * as React from "react";
import Carousel from "react-material-ui-carousel";
import IconButton from "@material-ui/core/IconButton";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Item from "./MainCarousel/Item";
import {graphql, useStaticQuery} from "gatsby";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    buttonWrapper: {
        position: "absolute",
        backgroundColor: "transparent",
        height: "100%",
        top: "0",
        '&:hover': {
            '& $button': {
                backgroundColor: theme.palette.primary.main,
                opacity: "0.8"
            }
        }
    },
    button: {
        margin: "0 10px",
        position: "relative",
        backgroundColor: theme.palette.primary.main,
        top: "calc(50% - 20px) !important",
        color: "#ffffff",
        fontSize: "30px",
        transition: "200ms",
        cursor: "pointer",
        opacity: "0.1",
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: "0.8 !important"
        },
    }
}));

const MainCarousel = () => {
    const carouselClasses = useStyles();

    const {logo1, sauna241, sauna341, theSet, mobiles} = useStaticQuery(
        graphql`
      query {
        logo1: file(relativePath: { eq: "main-carousel/0001.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1280
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        },
        sauna241: file(relativePath: { eq: "main-carousel/0003.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1280
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        },
        sauna341: file(relativePath: { eq: "main-carousel/0004.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1280
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        },
        theSet: file(relativePath: { eq: "main-carousel/0005.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1280
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        },
        mobiles: file(relativePath: { eq: "main-carousel/0006.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1280
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
    );

    const items = [
        {
            name: "deseczka.com",
            description: "Eleganckie, kompaktowe, idealne...",
            image: logo1,
            top: null,
            bottom: '7%',
            left: '5%',
            right: null,
            isDisplayed: false
        },
        {
            name: "Mobilna sauna",
            description: "Wszędzie tam gdzie tylko zapragniesz.",
            image: mobiles,
            top: '9%',
            bottom: null,
            left: '29%',
            right: null,
            isDisplayed: false
        },
        {
            name: "",
            description: "Domowe spa na wyciągnięcie ręki.",
            image: theSet,
            top: '17%',
            bottom: null,
            left: '0%',
            right: null,
            isDisplayed: false
        },
        {
            name: "",
            description: "Odrobina ciepła w domowym zaciszu.",
            image: sauna241,
            top: '73%',
            bottom: null,
            left: null,
            right: '0%',
            isDisplayed: false
        },
        {
            name: "",
            description: "Prestiż w ogrodzie, relaks o zachodzie.",
            image: sauna341,
            top: '28%',
            bottom: null,
            left: '55%',
            right: null,
            isDisplayed: false
        },
    ]

    return (
        <section id="home">
            <Carousel
                stopAutoPlayOnHover={false}
                indicatorContainerProps={{
                    style: {
                        display: 'none'
                    }
                }}
                navButtonsWrapperProps={{
                    className: carouselClasses.buttonWrapper
                }}
                navButtonsProps={{

                }}
                NavButton={({onClick, className, style, next, prev}) => {
                    // Other logic

                    return (
                        <IconButton onClick={onClick} className={carouselClasses.button} style={style}>
                            {next && <NavigateNextIcon/>}
                            {prev && <NavigateBeforeIcon/>}
                        </IconButton>
                    )
                }}
            >
                {
                    items.map( (item, i) => <Item key={i} item={item} /> )
                }
            </Carousel>
        </section>
    );
}

export default MainCarousel