import * as React from "react";
import {Box, Divider, Link, Paper, Typography} from "@material-ui/core";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    circlePaper: {
        borderRadius: '50%',
        width: '120px',
        height: '120px',
        overflow: 'hidden',
    },
    contactName: {
        color: theme.palette.primary.dark,
        fontWeight: 600,
        letterSpacing: '1px'
    },
    contactNumber: {
        paddingTop: '0.5rem',
        paddingBottom: '1rem',
        paddingLeft: '0.5rem',
    }
}));

const PersonalRight = ({name, phoneNumber, children}) => {
    const classes = useStyles();

    return (
        <Grid container item alignItems={'center'} alignContent={'center'} xs={12} sm={12} md={6} lg={4}>
            <Box style={{margin: '5%'}}>
                <Paper className={classes.circlePaper} elevation={3}>
                    {children}
                </Paper>
            </Box>
            <Box flexGrow={1}>
                <Typography className={classes.contactName} variant={'subtitle1'}>{name}</Typography>
                <Divider />
                <Typography className={classes.contactNumber}>
                    <Link href={"tel:" + phoneNumber} underline="hover" color="inherit">
                        <PhoneAndroidIcon color={"secondary"} style={{verticalAlign: 'middle'}} /> {phoneNumber}
                    </Link>
                </Typography>
            </Box>
        </Grid>
    );
}

export default PersonalRight;