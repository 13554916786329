import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    AppBar,
    Box, Grow,
    Tab,
    Tabs,
    Typography
} from "@material-ui/core";
import SectionTitle from "./SectionTitle";
import PropTypes from 'prop-types';
import BaliaZimna from "./Offer/BaliaZimna";
import BaliaMobilna from "./Offer/BaliaMobilna";
import BaliaOgrodowa from "./Offer/BaliaOgrodowa";
import Sauna24 from "./Offer/Sauna24";
import Sauna24Long from "./Offer/Sauna24Long";
import Sauna34 from "./Offer/Sauna34";
import SaunaMobi from "./Offer/SaunaMobi";
import SaunaMobiAqua from "./Offer/SaunaMobiAqua";
import {useInView} from "react-intersection-observer";
import Sauna24In from "./Offer/Sauna24In";


const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 600
    },
    mainFeaturedPost: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    mainFeaturedPostContent: {
        position: 'relative',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
            paddingRight: 0,
        },
    },
    aboutPaper: {
        // height: "100%",
        overflow: "hidden",
    },
    aboutTitle: {
        padding: '0.7rem',
        paddingBottom: '0.3rem',
        color: theme.palette.primary.contrastText
    },
    aboutContent: {
        padding: '0.7rem'
    },
    aboutFooter: {
        padding: '0.7rem',
        height: "100%"
    },
    descriptionGridSeparator: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    },
    offerAppBar: {
        backgroundImage: 'url("../static/tileable-wood-colored-h.png")',
        backgroundSize: '370px',
    }
}));

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

const Offer = () => {
    const offerClasses = useStyles();

    const [value, setValue] = React.useState('sauna-24-in');

    const [showed, setShowed] = React.useState(0);

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
        delay: 500
    });

    const delayedIn = (timeout) => {
        setTimeout(() => {
                setShowed(showed + 1)
            },
            timeout
        )
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <section id="offer">
            <SectionTitle>Oferta</SectionTitle>
            <Box style={{overflow: 'hidden'}} ref={ref}>
                <AppBar position="static" className={offerClasses.offerAppBar}>
                    <Grow direction="left" in={inView} timeout={400} onEnter={() => delayedIn(200)}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="wrapped label tabs example"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab
                                value="sauna-24"
                                label={
                                    <div>
                                        <Typography variant="subtitle2">
                                            Sauna
                                        </Typography>
                                        <Typography variant="caption">
                                            2,4m
                                        </Typography>
                                    </div>
                                }
                                wrapped
                                {...a11yProps('four')}
                            />
                            <Tab
                                value="sauna-24-long"
                                label={
                                    <div>
                                        <Typography variant="subtitle2">
                                            Sauna
                                        </Typography>
                                        <Typography variant="caption">
                                            2,4m long
                                        </Typography>
                                    </div>
                                }
                                wrapped
                                {...a11yProps('five')}
                            />
                            <Tab
                                value="sauna-34"
                                label={
                                    <div>
                                        <Typography variant="subtitle2">
                                            Sauna
                                        </Typography>
                                        <Typography variant="caption">
                                            3,4m
                                        </Typography>
                                    </div>
                                }
                                wrapped
                                {...a11yProps('six')}
                            />
                            <Tab
                                value="sauna-mobi"
                                label={
                                    <div>
                                        <Typography variant="subtitle2">
                                            Sauna
                                        </Typography>
                                        <Typography variant="caption">
                                            mobilna
                                        </Typography>
                                    </div>
                                }
                                wrapped
                                {...a11yProps('seven')}
                            />
                            <Tab
                                value="sauna-mobi-aqua"
                                label={
                                    <div>
                                        <Typography variant="subtitle2">
                                            Sauna
                                        </Typography>
                                        <Typography variant="caption">
                                            mobilna 'aqua'
                                        </Typography>
                                    </div>
                                }
                                wrapped
                                {...a11yProps('eight')}
                            />
                            <Tab
                                value="balia"
                                label={
                                    <div>
                                        <Typography variant="subtitle2">
                                            Balia
                                        </Typography>
                                        <Typography variant="caption">
                                            ogrodowa
                                        </Typography>
                                    </div>
                                }
                                wrapped
                                {...a11yProps('three')}
                            />
                            <Tab
                                value="balia-zimna"
                                label={
                                    <div>
                                        <Typography variant="subtitle2">
                                            Balia
                                        </Typography>
                                        <Typography variant="caption">
                                            zimna
                                        </Typography>
                                    </div>
                                }
                                wrapped
                                {...a11yProps('one')}
                            />
                            <Tab
                                value="balia-mobi"
                                label={
                                    <div>
                                        <Typography variant="subtitle2">
                                            Balia
                                        </Typography>
                                        <Typography variant="caption">
                                            mobilna
                                        </Typography>
                                    </div>
                                }
                                wrapped
                                {...a11yProps('two')}
                            />
                        </Tabs>
                    </Grow>
                </AppBar>
                <TabPanel value={value} index="sauna-24-in">
                    <Sauna24In offerClasses={offerClasses} />
                </TabPanel>
                <TabPanel value={value} index="sauna-24">
                    <Sauna24 offerClasses={offerClasses} />
                </TabPanel>
                <TabPanel value={value} index="sauna-24-long">
                    <Sauna24Long offerClasses={offerClasses} />
                </TabPanel>
                <TabPanel value={value} index="sauna-34">
                    <Sauna34 offerClasses={offerClasses} />
                </TabPanel>
                <TabPanel value={value} index="sauna-mobi">
                    <SaunaMobi offerClasses={offerClasses} />
                </TabPanel>
                <TabPanel value={value} index="sauna-mobi-aqua">
                    <SaunaMobiAqua offerClasses={offerClasses} />
                </TabPanel>
                <TabPanel value={value} index="balia">
                    <BaliaOgrodowa offerClasses={offerClasses} />
                </TabPanel>
                <TabPanel value={value} index="balia-zimna">
                    <BaliaZimna offerClasses={offerClasses} />
                </TabPanel>
                <TabPanel value={value} index="balia-mobi">
                    <BaliaMobilna offerClasses={offerClasses} />
                </TabPanel>
            </Box>
        </section>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
            style={{paddingTop: '1rem'}}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default Offer